<template>
    <div v-if="hasResource('activity_management')" class="aops-user-incentive">
      <div class="aop-searchForm search-form">
         <!-- <el-button
          class="black-btn"
          :loading="exportBtnLoading"
          @click="handleAssetsExport"
          >导出数据</el-button> -->
        <div class="aop-fix">
          <el-form :inline="true" :model="filter" label-width="90px">
            <el-form-item label="活动名称">
               <el-select v-model="filter.activity_id">
                <el-option v-for="item in activies" :key="item.value" :label="item.label" :value="item.value"/>
              </el-select>
            </el-form-item>
            <el-form-item label="用户名称">
              <el-input
                v-model="filter.username"
                placeholder="输入用户名称"
                clearable
                @keyup.enter.native="addByEnterKey"
              ></el-input>
            </el-form-item>
               <el-form-item label="用户邮箱">
              <el-input
                v-model="filter.email"
                placeholder="输入用户邮箱"
                clearable
                @keyup.enter.native="addByEnterKey"
              ></el-input>
            </el-form-item>
            <el-form-item label="是否被拉新">
              <el-select v-model="filter.invitee">
                <el-option v-for="item in options" :key="item.value" :label="item.label" :value="item.value"/>
              </el-select>
            </el-form-item>
            <el-form-item label="是否付费">
              <el-select v-model="filter.pay">
                <el-option v-for="item in options" :key="item.value" :label="item.label" :value="item.value"/>
              </el-select>
            </el-form-item>
          </el-form>
          <div class="aop-textBtn">
            <span class="aop-clickButton" @click="inquire">查询</span>
            <span class="aop-clearButton aop-clickButton" @click="reset">清空</span>
          </div>
        </div>
      </div>
      <el-table
        v-loading="isTableLoading"
        :data="listData"
        class="data-table"
        header-cell-class-name="table-head"
        cell-class-name="table-cell"
      >
        <el-table-column
            v-for="col in tableColumns"
            :key="col.label"
            :prop="col.prop"
            :label="col.label"
            :width="col.width"
            :min-width="col.minWidth"
            :align="col.align"
            :fixed="col.fixed">
            <template slot-scope="{row}">
                <div v-if="col.prop === 'invitee' || col.prop === 'pay'">
                {{`${row[col.prop]}` === '1' ? "是":"否"}}
                </div>
                <div v-else-if="col.prop === 'award_desc'">{{getAwarDesc(row)}}</div>
                <div v-else-if="col.prop === 'operation'" class="pa_icon-btn icon-btn">
                    <el-button
                        size="mini"
                        type="text"
                        @click="handleShowDetail(row)" >
                        <i class="iconfont">&#xe662;</i>查看详情
                    </el-button>
                </div>
                <div v-else>{{row[col.prop] || '-'}}</div>
            </template>
        </el-table-column>
      </el-table>
      <div v-if="total != 0"  class="aop-pagination">
        <el-pagination
          :current-page.sync="currentPage"
          :page-sizes="[10, 20, 50]"
          :page-size="pageSize"
          layout="total, sizes, prev, pager, next, jumper"
          :total="total"
          @size-change="handleSizeChange"
          @current-change="handleCurrentChange"
        ></el-pagination>
      </div>
    </div>
</template>
<script>
import { formatDate } from 'ecoplants-lib';
import api from '@/api/promotions';
import { tableColumns, options, filter } from './data';

export default {
    /**
    * 基础数据&定义数据
     @return{*}
    */
    data() {
        return {
            tableColumns,
            options,
            activies: [
                {
                    value: 0,
                    label: '全部'
                }
            ],
            isTableLoading: false,
            exportBtnLoading: false,
            listData: [],
            data: [],
            total: 0,
            pageSize: 10,
            currentPage: 1,
            filter: { ...filter }
        };
    },
    /**
    * 创建时
    */
    created() {
        this.getActivityList();
        this.getList();
    },
    methods: {
        /**
        * 查询
         * @param {*} e 值
        */
        addByEnterKey(e) {
            if (e.keyCode == 13) {
                this.currentPage = 1;
                this.getList();
            }
        },
        /**
        * 查询
         * @param {*} val 值
        */
        handleSizeChange(val) {
            this.pageSize = val;
            this.getList();
        },
        /**
        * 查询
         * @param {*} val 值
        */
        handleCurrentChange(val) {
            this.currentPage = val;
            this.getList();
        },
        /**
        * 重置
        */
        reset() {
            this.currentPage = 1;
            this.filter = {
                ...filter
            };
        },
        /**
        * 查询
        */
        inquire() {
            this.currentPage = 1;
            this.getList();
        },
        /**
        * 查询活动类型
           @returns{*}
        */
        getActivityList() {
            const all = [{
                value: 0,
                label: '全部'
            }];
            try {
                api.getActivityList().then((res) => {
                    if (res.data.code === 10200) {
                        const list = (res.data.data.items || []).map((item) => {
                            item.value = item.activity_id;
                            const start = item.start_time ? formatDate(item.start_time * 1000) : '';
                            if (`${item.end_time}` === '0' || !item.end_time) {
                                item.label = `${item.activity_title}_${start}_进行中`;
                            } else {
                                const end = formatDate(item.end_time * 1000);
                                item.label = `${item.activity_title}_${start}_${end}`;
                            }
                            return item;
                        });
                        this.activies = [...all, ...list];
                    }
                });
            } catch (e) {
                return e;
            }
        },
        /**
        * 列表数据
           @returns{*}
        */
        getList() {
            this.isTableLoading = true;
            const data = {
                limit: this.pageSize,
                page: this.currentPage,
                filter: {
                    ...this.filter
                }
            };
            try {
                api.getIncentiveList(data).then((res) => {
                    if (res.data.code === 10200) {
                        this.listData = res.data.data.items;
                        this.data = this.listData;
                        this.total = res.data.data.total;
                        this.currentPage = res.data.data.page;
                    }
                    this.isTableLoading = false;
                });
            } catch (e) {
                return e;
            }
        },
        /**
         * 导出
         */
        handleAssetsExport() {
            this.exportBtnLoading = true;
            const data = {
                filter: {
                    ...this.filter
                }
            };
            try {
                api.exportList(data).then((res) => {
                    if (res?.data?.code === 10200) {
                        const url = res.data.data.export_url;
                        if (url) {
                            this.downloadFile(url);
                        }
                    } else {
                        this.$$error(res.data.message);
                    }
                    this.exportBtnLoading = false;
                });
            } catch (error) {
                this.$$error(error);
                this.exportBtnLoading = false;
            }
        },
        /**
         * 设置时间
         * @param {*} row 值
          @return{*}
        */
        getAwarDesc(row) {
            let info = '';
            if (row.score && `${row.score}` !== '0') {
                info = `+${row.score}积分`;
            }
            if (row.subscription && `${row.subscription}` !== '0') {
                info = `${info ? `${info}，` : '+'}${row.subscription}天订阅`;
            }
            return info || '-';
        },
        /**
        * 查看详情
         * @param {*} val 值
        */
        handleShowDetail(val) {
            this.$router.push({
                path: '/user-incentive-detail',
                query: {
                    id: val.customer_id,
                    activity_id: this.filter.activity_id
                }
            });
        }
    }
};
</script>
<style lang="less">
    @import "./index.less";
</style>

export const tableColumns = [
    { prop: 'username', label: '用户名称', width: 220, align: 'left', fixed: 'left' },
    { prop: 'email', label: '用户邮箱', width: 240, align: 'left' },
    { prop: 'invitee', label: '是否被拉新', width: 140, align: 'left' },
    { prop: 'pay', label: '是否付费', width: 140, align: 'left' },
    { prop: 'invitee_num', label: '拉新注册人数', width: 140, align: 'left' },
    { prop: 'pay_num', label: '拉新付费人数', width: 140, align: 'left' },
    { prop: 'award_desc', label: '获得奖励', minWidth: 200, align: 'left' },
    { prop: 'operation', label: '操作', width: 280, align: 'left', fixed: 'right' }
];

export const options = [
    {
        value: 0,
        label: '全部'
    },
    {
        value: 1,
        label: '是'
    },
    {
        value: 2,
        label: '否'
    }
];

export const filter = {
    activity_id: 0,
    username: '',
    email: '',
    invitee: 0,
    pay: 0
};
